<script setup lang="ts">
const store = useMainStore();
const firebase = useFirebaseStore();
const infoStore = useInfoStore();

watch(
  () => store.isAuthorized,
  (v) => {
    if (v) {
      infoStore.init();
      firebase.request();
    } else infoStore.close();
  },
  { immediate: true },
);

onServerPrefetch(async () => {
  if (store.authorized) await store.loadUser();
});

onMounted(() => {
  firebase.init();
  if (store.authorized) store.loadUser(true);
});
</script>

<template>
  <div class="mainLayout">
    <LayoutHeadMenu />

    <div class="mainLayout__content">
      <NuxtPage />
    </div>

    <DialogPairedGameInvited />
  </div>
</template>

<style lang="scss">
.mainLayout {
  --header-height: 64px;

  position: relative;
  min-height: 100svh;
  display: flex;
  flex-direction: column;

  &__content {
    min-height: 100svh;
    display: flex;
    flex-direction: column;
  }
}
</style>
